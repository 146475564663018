import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import LandingPage from 'src/components/LandingPage/LandingPage'

const LpGeneralPage = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      hero1: file(relativePath: { eq: "hero-waterfall.jpg" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const heroProps = {
    titleText: `Lose 1 kg per week, look and feel your best, without feeling hungry!`,
    subtitleText: `Find out how to finally see lasting weight-loss, totally risk free by talking to an expert. Guaranteed results!`,
    image: data.hero1.childImageSharp.fluid,
  }
  return <LandingPage location={location} heroProps={heroProps} />
}

export default LpGeneralPage
